@import "./variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  @media (max-height: 680px) and (max-width: 380px) {
    font-size: 12px;
  }
}

@import "./utils.scss";
@import "../Views/Auth/authStyles.scss";
@import "./dmatstyles";
@import "./components";
@import "./layout";
@import "./meroshare";
@import "./dmatminor";
