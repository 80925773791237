.title {
  font-size: 1.5rem;
  font-weight: 500;
}
.subtitle {
  font-size: 0.8rem;
  color: #9eadad;
}
.subtitle-email {
  font-size: 0.8rem;
  color: #398cff;
}

.profileContainer {
  min-height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.topProfileContainer {
  padding: 1.5rem 5vw;
  border-bottom: 1px solid #d3e0e0;
}

.profileStatsContainer {
  height: 150px;
  padding: 1.5rem 5vw;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.statsMainBox {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.miniBox {
  height: 24px;
  width: 24px;
  border-radius: 4px;
}

.dematListsContainer {
  padding: 1.5rem 5vw;
}

.dematListItemContainer {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.2rem;
  border: 1px solid #d3e0e0;
  border-radius: 8px;
  margin-block: 1rem;
}

.dematFormStatusComplete {
  border: 1px solid #80eed0;
  color: #80eed0;
  padding: 5px 10px;
  border-radius: 24px;
}
.dematFormStatusIncomplete {
  border: 1px solid $warning;
  color: $warning;
  padding: 5px 10px;
  border-radius: 24px;
}
