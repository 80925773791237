//Textinput
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

.inputContainer {
  width: 315px;
  height: 40px;
  padding: 5px;
}
.boxInput {
  border: 1.5px solid $disabledColor;
  border-radius: 8px;
}
.underlineInput {
  border-bottom: 1.5px solid #60706f !important;
  padding: 7px 0 2px 0 !important;
  border-radius: 0 !important;
}
.underlineInput:focus-within {
  border-bottom: 1.5px solid blue !important;
}
.borderedSelect:focus-within {
  border-color: blue;
}
.underlineInput .ant-input-prefix {
  margin-right: 10px;
}

.underlineInput .ant-input-number-input {
  padding: 0px !important;
}
.dottedInput {
  border-bottom: 1.5px dotted #60706f !important;
  // padding: 7px 0 12px 0;
  // border-radius: 0 !important;
}
.otp-input {
  border-bottom: 1.5px solid #2e4342 !important;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
.otp input {
  width: 50px !important;
  border: 0;
  border-bottom: 1.5px solid #2e4342 !important;
}
.react-code-input > input {
  &:focus-visible {
    outline: none;
    border: none;
    border-block-end: 1.5px solid rgb(46, 67, 66);
  }
}
.errorInput {
  border-color: red;
}

.underlineIcon {
  margin-left: -10px;
  margin-right: 10px;
}

//Button
.forwardBtn {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  background-color: $primaryColor;
}

.forwardIcon {
  color: white !important;
  font-size: 1.3rem;
}

.btnDisabled {
  cursor: not-allowed;
  background-color: $disabledColor;
}

//dropdown
.dropdownContainer {
  padding-block: 5px;
  padding-inline: 10px;
  border: 1px solid #d3e0e0;
  border-radius: 8px;
  // width: 315px;
  height: 32px;
  background-color: white;
}
.dropdownContainer-underline {
  padding-block: 5px;
  border-bottom: 1.5px solid #60706f;
  // width: 315px;
  height: 32px;
  background-color: white;
}

.dropdownMenu {
  margin-top: 10px;
  border-radius: 10px;
  height: 100px;
  overflow-y: scroll;
}
.dropdownMenu-small {
  height: 100px;
}
.dropdownMenu-big {
  height: 150px;
}

.dropdownView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 100%;
}
.dropdownItem {
  margin: 0;
  padding-block: 5px;
  padding-inline: 10px;
}

.dropdownItem-active {
  color: white !important ;
  background-color: $primaryColor !important;
  border-radius: 5px !important;
}

.dropdownTextContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdownText-active {
  color: white;
}

.ant-select-dropdown {
  border-radius: 8px;
}

//checkbox
.checkboxContainer {
  border-radius: 8px;
  padding: 12px 20px;
  border: 1.5px solid #d3e0e0;
  cursor: pointer;
}
.checkbox-container-bg {
  background-color: #e5fcf6;
}
.checkboxTitle {
  font-weight: 600;
  font-size: 1.1rem;
}
.checkboxSubtitle {
  color: #9eadad;
}
.checkboxLabel {
  color: #60706f;
}
.ant-checkbox-inner {
  border: 2px solid $primaryColor !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primaryColor !important;
}
.round-checkbox .ant-checkbox-inner {
  border-radius: 50px !important;
  border-color: #80eed0 !important;
}
.round-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 50px !important;
  background-color: $primaryColor !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  visibility: none !important;
}
.checkboxActive {
  background-color: $greyColor;
}
.checkboxContent {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-between;
}

//outline button checkbox
.outlineBtn-checkbox {
  height: 40px;
  border-radius: 8px;
  padding-inline: 10px;
  border: 1px solid #d3e0e0;
  overflow: hidden;
  cursor: pointer;
}
.outlineBtn-checkboxActive {
  background-color: #d3fbf0 !important;
  border: 1px solid #d3fbf0;
}

//modal
.modalContainer {
  background-color: white;
  border-radius: 24px;
  height: 170px;
}
.ant-modal-content {
  overflow: hidden;
  border-radius: 8px;
}

.modalButton {
  cursor: pointer;
  padding: 5px 20px 5px 20px;
}

//stepper custom
.ant-steps-small .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  margin: 8px 5px;
  font-size: 0 !important;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
  background-color: #d3e0e0;
  border: 0;
}

.ant-steps-item-active .ant-steps-item-icon {
  width: 24px !important;
  height: 24px !important;
  margin: 0;
  font-size: 12px !important;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
  border: 0;
  background-color: $primaryColor !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: transparent;
  border-bottom: 1px #d3e0e0 dashed;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: transparent;
  border-bottom: 1px #d3e0e0 dashed;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: transparent;
  border-bottom: 1px #d3e0e0 dashed;
}

//button custom
.submit-btn {
  height: 2.5rem !important;
  font-size: 1.02rem !important;
  background-color: $primaryColor !important;
  color: white !important;
  font-weight: 500;
  border: 0 !important;
}
.clear-btn {
  height: 2rem !important;
  font-size: 0.9rem !important;
  background-color: $primaryColor !important;
  color: white !important;
  font-weight: 500;
  border: 0 !important;
}
.cancel-link-btn {
  margin-top: 0.5rem !important;
  font-size: 1.02rem !important;
  color: #60706f !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.scan-outline-btn {
  border-radius: 8px !important;
  border: 1.5px solid #60706f !important;
  padding: 5px;
}

.content-container {
  min-height: 70vh;
}
.greeting-text {
  font-weight: 500;
  color: #60706f;
  font-size: 1.02rem;
}
.greeting-name {
  font-weight: 500;
  color: $primaryColor;
  font-size: 1.02rem;
}

.datepicker-underline {
  border-bottom: 1.5px solid #60706f !important;
  // width: 100%;
  .ant-row {
    margin-bottom: 0px;
  }
}

// .datepicker-underline .ant-picker-borderless {
//   border-bottom: 1.5px solid #60706f !important;
//   // width: 100%;
// }

//for progressbar to stick to the top of the screen
.ant-progress-inner {
  vertical-align: top;
}

//when hover the image component of ant
.image-round-mask {
  border-radius: calc(145px / 2);
}

//underlined select
.underlineSelect .ant-select-selector {
  border: 0 !important;
  border-bottom: 1.5px solid #60706f !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-radius: 0 !important;
  // margin-top: 10px;
}
//bordered select
.borderedSelect .ant-select-selector {
  border-radius: 8px !important;
}

.upload-drawer .ant-drawer-content {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.ant-select-selection.ant-select-selection--single
  > div
  > div
  > div
  > div
  + div {
  margin-top: -5px;
  padding: 4px 5px 5px 14px !important;
}
.signature {
  overflow-x: hidden;
}
.signature-terms {
  // transform: rotate(90deg);
  height: 4rem;
  width: 4rem;
}
.nepali-calendar .calender {
  // position: relative !important;
  // top: 36px;
  width: 100%;

}

.nepali-calendar input {
  width: 100%;
  border: 0;
  border-bottom: 1.5px solid #60706f;
  padding-top: 5px;
  // padding-bottom: 7px;
  outline: none;

  // background-color: white;
  // color: white;
  opacity: 0;
}
.nepali-calendar-wrapper {
  position: relative;
  // background: green;
  border-bottom: 1.5px solid #60706f;
}

.nepali-calendar-value {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 5px;
  font-size: 14px;

  // top:0;
  // right: 0;
  // z-index: 2;
}
.nepali-calendar-wrapper-bordered {
  // position: relative;
  // border: 1.5px solid #d9d9d9;
  // border-radius: 8px;
  // padding-left: 10px;

  position: relative;
  // background: green;
  border: 1.5px solid #d9d9d9;
  border-radius: 8px;
  // min-height: 28px;
  // height: 32px;
  // max-height: 400px;
}
// .nepali-calendar-wrapper-bordered .nepali-calendar .calender {
//   position: absolute !important;
// }
.nepali-calendar-value-bordered {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 10px;
  margin-left: 8px;
  font-size: 14px;
  // top:0;
  // right: 0;
  // z-index: 2;
}
.nepali-date-picker .calender td.month-day.selected::after {
  background-color: $primaryColor !important;
}
.calendar-controller .icon-today svg g g path {
  fill: $primaryColor !important;
}
.drop-down .option-wrapper .active {
  background: $primaryColor !important;
}
//showing global message from the bottom (remove if not needed)
.ant-message {
  bottom: 20px;
  top: initial;
}
.ant-btn-text {
  font-size: 1.02rem !important;
  color: #60706f !important;
  font-weight: 500 !important;
}
.ant-btn > span{
  font-weight: 500 !important;
}
.ant-message-notice-content {
  background: rgba(235, 87, 87, 0.1) !important;
  border: 1px solid #eb5757;
}

.custom_notification .ant-notification-notice-close {
  display: none;
}

.custom_notification_error {
  background: rgba(235, 87, 87, 0.8) !important;
  border: 1px solid #eb5757;
  color: white;
}
.warning-notification {
  background: rgba(245, 167, 15, 0.8) !important;
  border: 1px solid #f5a70f;
  color: white;
}
.custom_notification_error .ant-notification-notice-message {
  color: white !important;
}
.warning-notification .ant-notification-notice-message {
  color: white !important;
}

// .nepali-date-picker .calender {
//   // position: absolute;
// }

//thumbprint box
.thumbprintBoxContainer {
  height: 10rem;
  width: 8rem;
  border: 1px solid #d3e0e0;
  padding-top: 0.5rem;
}

//autocomplete places
.autocomplete-places-container {
  position: absolute;
  top: 30px;
  left: 15vw;
  right: 5vw;
  z-index: 100;
  width: 80vw;
}
.autocomplete-places-back-btn {
  position: absolute;
  top: 33px;
  left: 5vw;
  z-index: 100;
  width: 10vw;
  cursor: pointer;
}
.map-submit-container {
  position: absolute;
  bottom: 30px;
  left: 5vw;
  right: 5vw;
  z-index: 100;
}

//for easier UI testing only
.bg-red {
  background: red !important;
}

//khalti ui
._3R-ELLN_4o_XTlec-3AC5I {
  overflow-y: scroll;
  padding-bottom: 300px !important;
}

// @media only screen and (max-width: 768px) {
//   ._3R-ELLN_4o_XTlec-3AC5I {
//     position: relative;
//     padding-bottom: 300px !important;
//   }
// }

.ant-spin-dot-item {
  background-color: $primaryColor;
}
