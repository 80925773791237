:root {
  --grey-text: #60706f;
  --orange-text: #f5a70f;
  --blue: #398cff;
  --green: #0ab290;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.container > section {
  width: 375px;
  background: rgba(246, 248, 250, 0.678);
  height: auto;
  padding: 30px 15px;
}

.section-title {
  font-size: 20px;
}

.card {
  margin-top: 30px;
  margin-inline: auto;
  width: 90%;
  background-color: white;
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: 0.1px 0.1px 20px 1px rgba(151, 150, 150, 0.164);
  z-index: 1;
}

.card-header {
  flex-direction: column;
  width: 100%;
}

.status {
  width: 130px;
  padding: 5px 0;
  margin: 10px 0;
}
.status.success {
  background-color: #e5fcf6;
  color: #00dda2;
  font-weight: 300;
}

.amount {
  flex-direction: column;
  margin-top: 10px;
}
.amount p {
  font-size: 13px;
  color: var(--grey-text);
  font-weight: 300;
}
.amount span {
  font-size: 20px;
  color: var(--orange-text);
  font-weight: 700;
  margin-top: 5px;
}

.card-section-breaker {
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  margin: 20px 0 10px 0;
}

.card-content {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 30px;
  z-index: 1;
}

.card-flex {
  flex: calc(50% - 20px);
}

.card-flex > p {
  font-size: 13px;
  color: var(--grey-text);
  font-weight: 300;
  margin-bottom: 5px;
}
.card-flex > span {
  font-size: 14px;
  font-weight: 700;
}

.download-btn {
  width: 80%;
  margin-inline: auto;
  color: var(--blue);
  border: 1px solid var(--blue);
  border-radius: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
}
.download-btn::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--blue);
  display: inline-block;
  transform: scaleX(0) translateZ(-1px);
  z-index: -1;
  transform-origin: left;
  transition: 0.3s;
}
.download-btn:hover {
  color: white;
}
.download-btn:hover::after {
  transform-origin: right;
  transform: scaleX(1) translateZ(-1px);
}

.download-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
  margin: 15px;
}
.card-padding {
  padding: 15px;
}

.icon-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f2f5;
}

.table-header {
  font-size: 14px;
  text-transform: capitalize;
  margin: 20px 0 5px 0;
}

.space-between {
  flex-direction: column;
  gap: 20px;
}

.space-between .card-flex {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.green-back {
  background-color: var(--green);
  padding: 30px 15px;
  padding-bottom: 60px;
}

.green-back .download-btn {
  color: white;
  border: 1px solid white;
}
.green-back .download-btn::after {
  background-color: white;
}
.green-back .download-btn:hover {
  color: var(--blue);
}

.footor {
  padding: 20px;
  justify-content: space-between;
}

.footor > p {
  cursor: pointer;
  font-size: 13px;
  color: var(--grey-text);
  font-weight: 300;
  margin-bottom: 5px;
}
.next-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--green);
  color: white;
  cursor: pointer;
}

.card-curly {
  padding-bottom: 50px;
}
