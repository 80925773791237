@font-face {
  font-family: "AvenirLTStd";
  src: local("AvenirLTStd"), url(./fonts/AvenirLTStd.ttf) format("truetype");
}

@font-face {
  font-family: "AvenirLTStd";
  font-weight: 500;
  src: local("AvenirLTStd"),
    url("./fonts/AvenirLTStd-Heavy.woff") format("woff");
}
@font-face {
  font-family: "AvenirLTStd";
  font-weight: 300;
  src: local("AvenirLTStd"),
    url("./fonts/AvenirLTStd-Roman.woff") format("woff");

}
@font-face {
  font-family: "AvenirLTStd";
  font-weight: 600;
  src: local("AvenirLTStd"),
    url("./fonts/AvenirLTStd-Heavy.woff") format("woff");
}
@font-face {
  font-family: "AvenirLTStd";
  font-weight: 700;
  src: local("AvenirLTStd"),
    url("./fonts/AvenirLTStd-Heavy.woff") format("woff");
}
@font-face {
  font-family: "AvenirLTStd";
  font-weight: 800;
  src: local("AvenirLTStd"),
    url("./fonts/AvenirLT95Black.ttf") format("truetype");
}

* {
  font-family: AvenirLTStd, Arial, serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  overflow-x: hidden !important;
  scroll-behavior: smooth !important;
  height: 100% !important;
  overflow-y: auto;
}

body {
  position: relative !important;
}

#root {
  height: 100% !important;
}


.box-wrapper {
  background-color: #bdbdbd;
  border-radius: 5px;
  padding: 10px;
  height: 100%;
}