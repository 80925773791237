//first screen

.dmatTopContainer {
  min-height: 30vh;
  padding: 10vh 0;
}

.dmatBottomContainer {
  min-height: 70vh;
}
.dmatBottomSection {
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  padding: 10vh 0;
}

.outline-btn {
  height: 40px;
  background-color: white !important;
  color: #60706f !important;
  border: 1px solid #d3e0e0;
  border-radius: 8px;
  transition: background-color border 0.5s linear;
  .tick-hover {
    // display: none;
    color: white;
    font-size: 1.1rem;
  }
  &:hover {
    background-color: #d3fbf0 !important;
    border: 1px solid #d3fbf0;

    .tick-hover {
      color: $primaryColor;
      // display: block;
    }
  }
}

//citizenship screen
.pageWrapper {
  min-height: 100vh;
  width: 100vw;
  padding: 0 5vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.citizenshipTopContainer {
  padding-top: 2vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  height: 10vh;
}
.citizenshipMiddleContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem 0;
}
.citizenshipBottomContainer {
  height: 10vh;
  align-self: flex-end;
}

.citizenTab {
  height: 2px;
  width: 15px;
  background-color: #d3e0e0;
}
.citizenTab-active {
  width: 30px;
  background-color: $primaryColor;
}

// Guardian Details Screen

.dmatTopContainer-1 {
  min-height: 15vh;
  padding-bottom: 1rem;
}
.dmatBottomContainer-1 {
  min-height: 25vh;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5.5rem;
}

.dmatMiddleContainer-3by3 {
  min-height: 50vh;
  padding: 1.25rem 0;
}

.dmatMiddleContainer-2by2 {
  min-height: 50vh;
  // padding: 4rem 0 4rem 0;
  padding-top: 4rem;
}

//Permanent Address (3rd Page)
.fax-inputBox {
  height: 32px;
  border: 1px solid #d3e0e0;
  border-radius: 8px;
  padding-inline: 10px;
  cursor: pointer;
}
.fax-inputBox-expanded {
  height: 150px;
}
.fax-inputBox-expanded-finance {
  height: 330px;
}

//Cheque Screen
.chequeBody {
  padding: 3rem 5vw;
  // color: $primaryColor;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.manuallyBtn {
  border-color: $primaryColor;
  color: $primaryColor;
  font-size: 0.875rem;
}

.upload-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1vh;
}
.upload-subtitle {
  font-size: 1rem;
}

//TERMS PAGE
.termsLayout {
  min-height: 90vh;
  overflow-x: scroll;
  // display: flex;
}

.termsTopContainer {
  min-height: 10vh;
  border-bottom: 1px #d3e0e0 solid;
  display: flex;
  align-items: center;
  padding-inline: 5vw;
}

.termsMiddleContainer {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  padding-inline: 5vw;
  width: 100%;
}
.termsBottomContainer {
  height: 10vh;
  align-self: flex-end;
}

.dynamicForm {
  .ant-row {
    &.ant-form-item {
      &:first-child {
        .anticon {
          display: none;
        }
      }
    }
  }
}
