$directions: (
  left,
  right,
  center
);

@each $direction in $directions {
  .text-#{$direction} {
    text-align: #{$direction};
  }
}

//margin and padding
$spaceamounts: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7
);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}rem !important;
  }

  .my-#{$space} {
    margin: #{$space}rem 0 !important;
  }

  .mt-#{$space} {
    margin-top: #{$space}rem !important;
  }

  .mb-#{$space} {
    margin-bottom: #{$space}rem !important;
  }

  .ml-#{$space} {
    margin-left: #{$space}rem !important;
  }

  .mr-#{$space} {
    margin-right: #{$space}rem !important;
  }

  .p-#{$space} {
    padding: #{$space}rem !important;
  }

  .py-#{$space} {
    padding: #{$space}rem 0 !important;
  }

  .pt-#{$space} {
    padding-top: #{$space}rem !important;
  }

  .pb-#{$space} {
    padding-bottom: #{$space}rem !important;
  }

  .pl-#{$space} {
    padding-left: #{$space}rem !important;
  }

  .pr-#{$space} {
    padding-right: #{$space}rem !important;
  }
}

.w-100p {
  width: 100%;
}

.w-90p {
  width: 90%;
}

.px-5vw {
  padding: 0 5vw;
}

.h-100p {
  height: 100%;
}

.h-50p {
  height: 50%;
}

.h-100vh {
  height: 100vh;
}

.flex-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-flex {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.br-8 {
  border-radius: 8px !important;
}

.br-8:focus-within {
  border-radius: 8px !important;
  border-color: blue !important;
}

//text
.b-text {
  font-size: 1rem;
}

.b-0 {
  border: 0 !important;
}

.sb-text {
  font-weight: 700;
  font-size: 1.1rem;
}

.r-text {
  font-weight: 500;
  font-size: 1rem;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-800 {
  font-weight: 900 !important;
}

//12px
.xxs-text {
  font-size: 0.75rem;
}

.xs-text {
  font-weight: 350;
  font-size: 0.85rem;
}

//14px wala
.s-text {
  font-size: 0.875rem;
}

//20px wala
.m-text {
  font-size: 1.25rem;
}

//24px wala
.l-text {
  font-size: 1.5rem;
}

.text-grey {
  color: #60706f !important;
}

.text-light-grey {
  color: #9eadad !important;
}

.text-dark-grey {
  color: #60706f !important;
}

.text-green {
  color: $primaryColor !important;
}

.text-black {
  color: black !important;
}

.text-light-black {
  color: #2e4342 !important;
}

.text-warning {
  color: $warning !important;
}

.text-info {
  color: $info !important;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//cursor

.cursor-hover {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.fade-in-right-transition {
  animation: fadeInRight 1.3s;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-down-transition {
  animation: fadeInDown 1.3s;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up-transition {
  animation: fadeIn 1.3s;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
    transform: translateY(0);
  }

  0% {
    opacity: 0;
    transform: translateY(120px);
  }
}

.padL-0 {
  padding-left: 0 !important;
}