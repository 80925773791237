//dematLayoutWithProgressBar
.progressLayout {
  min-height: 100vh;
  // overflow-x: scroll;
  position: relative;
  // display: flex;
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
}

.progressTopContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //   align-self: flex-start;

  height: 11vh;
}

.progressMiddleContainer {
  // min-height: 80vh;
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
  padding: 1rem 5vw;
  padding-top: 4rem;
  // @media (max-height: 680px) and (max-width: 380px) {
  // }
  //   background: cyan;
}

.progressBottomContainer {
  // height: 25vh;
  // position: absolute;
  // bottom: 5vh;
  width: 100%;
  z-index: 1;
  padding-bottom: 28px;
  // right: 0;
  // left: 0;
  // align-self: flex-end;
  // background: green;
}

.progressBottomContainer2 {
  // height: 15vh;
  align-self: flex-end;
  // z-index: -10;

  //   background: green;
}

.progressRenderFooter {
  padding: 0 5vw;
  display: flex;
  height: 100%;
  width: 100%;
}

.footerDiv {

  // min-height: 20vh;
  .continue {
    background-color: #1db954;
    border-radius: 64px;
    color: #ffff;
    padding: 16px 50px;
    height: 52px;

    &:hover {
      background-color: #1db954;
      color: #ffff;
      border: 0;
    }
  }
}

.groupWrapper {
  background-color: #ffff;
  border-radius: 8px;
  padding: 16px;
}

.upload-signature {
  .ant-upload.ant-upload-select {
    width: 100%;
  }
}