.dematDetails {
  p {
    font-size: 12px;
    color: #9eadad;
    margin-top: -12px;
  }

  h3 {
    font-size: 16px;
    color: #60706f;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  .docHolder {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    .doc {
      text-align: center;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }

      h5 {
        font-size: 12px;
      }

      .iconHolder {
        width: 28px;
        height: 50px;
        background: #f6f8fa;
        box-shadow: 0px 4px 14px rgba(67, 70, 67, 0.13);
        border-radius: 7px;
        display: flex;
        justify-content: center;
        margin-bottom: 0.8rem;

        img {
          width: 24px;
          cursor: pointer;
        }
      }
    }
  }
}


.documentSubLabel{
  font-size: 12px;
  color: #9eadad;
  // margin-top: -12px;
}